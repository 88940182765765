@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Thin.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url("assets/fonts/MPLUSRounded1c-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-ExtraLight.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-ExtraLightItalic.ttf") format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-LightItalic.ttf") format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-MediumItalic.ttf") format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-SemiBoldItalic.ttf") format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-BoldItalic.ttf") format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-ExtraBoldItalic.ttf") format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AppMulish';
  src: url("assets/fonts/mulish/Mulish-BlackItalic.ttf") format('truetype');
  font-weight: 900;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'AppMulish', sans-serif;

  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
